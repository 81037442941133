import { useRouter } from "next/router";
import useSWR from "swr";
import { Section, getSectionTileDensity } from "../../components/public/Grid";
import { defaultFetcher } from "../HTTPClient";
import { APIV3 } from "../HTTPClientV3";
import {
  buildUrlWithQueryParams,
  APIQueryParams,
  PickQueryParams,
  QueryParams,
} from "../QueryParams";
import { JSONAPIDocument, serializers } from "../Serializer";
import { ServerError } from "../ServerError";

interface Deal {
  averageTotalSavingsCurrency: string;
  averageTotalSavingsAmount: number;
  averageTotalSavingsPrefix: "up_to" | "exact";
  id: string;
  title: string;
}
export interface Item {
  id: number;
  name: string;
  slug: string;
  deal?: Deal;
  partnerDescription?: string; // TODO: Agu, why is this needed
  description: string;
  seller: { name: string; logoUrl: string; symbolLogoUrl: string };
  recommendationScore?: number;
  endorsed?: boolean;
  categories: { id: number; name: string }[];
}

type ItemsQueryParams = Partial<
  PickQueryParams<
    | "category_ids"
    | "page_number"
    | "partner_ids"
    | "q"
    | "collection_ids"
    | "sorting"
  >
>;

const base = "/items";
function mapItemsToApiSortQueryParam(
  sort?: QueryParams["sorting"],
): APIQueryParams["sort"] | undefined {
  switch (sort) {
    case "asc":
      return "organization.name";
    case "desc":
      return "-organization.name";
    case "popular":
      return "-popularity";
    case "recommended":
      return "recommended";
    case "savings":
      return "-savings";
    default:
      return "recommended";
  }
}
function mapItemsToApiFilterQueryParams(
  params: ItemsQueryParams,
): Partial<APIQueryParams> {
  const queryParams: Partial<APIQueryParams> = {};
  Object.keys(params).forEach((param) => {
    switch (param) {
      case "category_ids":
        queryParams["filter[category]"] = params.category_ids;
        break;
      case "partner_ids":
        queryParams["filter[seller]"] = params.partner_ids;
        break;
      case "collection_ids":
        queryParams["filter[collection]"] = params.collection_ids;
        break;
      case "q":
        queryParams.search = params.q;
        break;
      default:
        break;
    }
  });
  return queryParams;
}
function mapItemsToApiQueryParams(params: ItemsQueryParams) {
  const queryParams: Partial<APIQueryParams> =
    mapItemsToApiFilterQueryParams(params);
  Object.keys(params).forEach((param) => {
    switch (param) {
      case "sorting":
        queryParams.sort = mapItemsToApiSortQueryParam(params.sorting);
        break;
      case "page_number":
        queryParams["page[number]"] = Number(params.page_number);
        break;
      default:
        break;
    }
  });
  return queryParams;
}

export function getSections(
  params: ItemsQueryParams,
  themeSections: Section[],
) {
  let usedSections: (Section | { type: "all"; title: "All" })[] = themeSections;

  const filterParams = mapItemsToApiFilterQueryParams(params);
  if (Object.keys(filterParams).length || !themeSections.length) {
    usedSections = [
      {
        type: "featured",
        title: "Featured",
      },
      {
        type: "all",
        title: "All",
      },
    ];
  }
  return usedSections;
}

export const tileItemAPIFields = {
  "fields[item]": [
    "name",
    "slug",
    "deal",
    "partner_description",
    "description",
    "recommendation_score",
    "endorsed",
    "seller",
    "categories",
  ].join(),
  "fields[deal]": [
    "average_total_savings_currency",
    "average_total_savings_amount",
    "average_total_savings_prefix",
    "id",
    "title",
  ].join(),
  "fields[category]": "name",
  "fields[seller]": ["name", "logo_url", "symbol_logo_url"].join(),
};

export const tileItemAPIIncludes = {
  include: ["seller", "categories", "deal"].join(","),
};

export function getItems(
  params: ItemsQueryParams,
  section: Section,
  page?: number,
  pageSize?: number,
  maxNumberOfColumnsOnDesktop?: number,
) {
  let sectionParams: Partial<APIQueryParams> = {};
  const filterParams = mapItemsToApiFilterQueryParams(params);
  const applyFilter = Object.keys(filterParams).length;
  const sortOptions = { sort: mapItemsToApiSortQueryParam(params.sorting) };
  switch (section.type) {
    case "collection":
    case "category":
    case "seller":
      sectionParams = {
        [`filter[${section.type}]`]: String(section.id),
        ...sortOptions,
      };
      break;
    case "newest":
    case "most_popular":
      sectionParams = {
        [`filter[${section.type}]`]: true,
        ...sortOptions,
      };
      break;
    case "featured":
      sectionParams = {
        [`filter[featured]`]: "true",
        ...sortOptions,
      };
      // when filtering the featured section should get the filters applied
      if (applyFilter) {
        sectionParams = {
          ...sectionParams,
          ...mapItemsToApiFilterQueryParams(params),
        };
      }
      break;
    case "all":
      sectionParams = {
        sort: "recommended",
        ...mapItemsToApiQueryParams(params),
        "filter[featured]": "false",
      };
      break;
    default:
      break;
  }

  // Gets the tile density for the section based on the max number of columns setting.
  const defaultSectionTileDensity = getSectionTileDensity(
    maxNumberOfColumnsOnDesktop,
  );

  const sectionPage = section.type !== "all" ? page : undefined;
  const sectionPageSize =
    section.type !== "all" ||
    (pageSize && pageSize >= defaultSectionTileDensity.large)
      ? pageSize
      : defaultSectionTileDensity.medium;
  if (sectionPage) {
    sectionParams["page[number]"] = sectionPage;
  }
  if (sectionPageSize) {
    sectionParams["page[size]"] = sectionPageSize;
  }
  const urlWithQueryParams = buildUrlWithQueryParams(
    base,
    // Sort is required so that permutation of variables don't affect the cache
    {
      ...tileItemAPIIncludes,
      ...sectionParams,
      ...tileItemAPIFields,
      no_track_analytics: "true",
    },
  );

  const request = () => APIV3.get<JSONAPIDocument<Item[]>>(urlWithQueryParams);

  return {
    json: () => defaultFetcher(request, serializers.item),
    fallbackKey: urlWithQueryParams,
  };
}

export function useItems(
  section: Section,
  page?: number,
  pageSize?: number,
  maxNumberOfColumnsOnDesktop?: number,
) {
  const router = useRouter();
  const { fallbackKey, json } = getItems(
    router.query,
    section,
    page,
    pageSize,
    maxNumberOfColumnsOnDesktop,
  );
  // TODO: cache is not working
  const { data, isValidating, mutate, error } = useSWR<
    { data: Item[]; meta: any },
    ServerError
  >(() => fallbackKey, json);
  return {
    loadingItems: isValidating,
    setDiscountList: mutate,
    itemsList: data,
    error,
  };
}
