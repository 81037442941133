import { FunctionComponent } from "react";
import { Theme, styled, useTheme } from "@mui/material";
import Image from "next/legacy/image";
import { defaultColorsConstant as defaultColors } from "theme/defaultValues";
import {
  Props as BaseTileHeaderProps,
  StandardDealName,
  StandardPartnerName,
} from "./StandardTileHeader";
import { StackedSavingAmount } from "./StackedTileHeader";
import EarningsBox from "../EarningsBox";

export const tileLogoContainerClassName = "elegant-image-container";

export const getHeaderItemPadding = (theme: Theme) => {
  if (
    !theme.settings.tiles.padding &&
    theme.settings.tiles.type === "elegant"
  ) {
    return "0.8rem";
  }
  if (theme.settings.tiles.padding && theme.settings.tiles.type === "elegant") {
    return theme.typography.pxToRem(theme.settings.tiles.padding);
  }
  return "0";
};

const ElegantHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  padding: 0,
}));

const TileLogoContainer = styled("div")(() => ({
  width: "100%",
  minHeight: "7rem",
  overflow: "hidden",
  position: "relative",
  backgroundColor: defaultColors.whiteDefault,
}));

const ElegantTileHeader: FunctionComponent<BaseTileHeaderProps> = ({
  id,
  item: { name, seller, deal },
}: BaseTileHeaderProps) => {
  const theme = useTheme();
  const { showVendorName } = theme.settings.tiles.titleContent;
  return (
    <>
      {seller.logoUrl && (
        <TileLogoContainer className={tileLogoContainerClassName}>
          <Image
            src={seller.logoUrl}
            alt={seller.name}
            objectFit="contain"
            objectPosition="center"
            layout="fill"
          />
        </TileLogoContainer>
      )}
      <ElegantHeader id={id}>
        {showVendorName && (
          <StandardPartnerName variant="h1" noWrap title={seller.name}>
            {seller?.name}
          </StandardPartnerName>
        )}
        <StandardDealName variant={showVendorName ? "h2" : "h1"} title={name}>
          {theme.settings.tiles.titleContent.showListingName
            ? name
            : deal?.title}
        </StandardDealName>
        {theme.settings.tiles.titleContent.showSavingAmount &&
          !!deal?.averageTotalSavingsAmount && (
            <StackedSavingAmount>
              <EarningsBox
                color={theme.palette.tileOthersColor.main}
                averageTotalSavingsAmount={deal.averageTotalSavingsAmount}
                averageTotalSavingsPrefix={deal.averageTotalSavingsPrefix}
                averageTotalSavingsCurrency={deal.averageTotalSavingsCurrency}
              />
            </StackedSavingAmount>
          )}
      </ElegantHeader>
    </>
  );
};

export default ElegantTileHeader;
