import { FunctionComponent } from "react";
import { styled, Typography, useTheme } from "@mui/material";
import { Item } from "../../../dataAccess/api/items";
import { TypographyWithEllipsis } from "../TypographyWithEllipsis";
import EarningsBox from "../EarningsBox";
import TileLogo from "./TileLogo";
import { getTileSpacing } from ".";
import { getHeaderItemPadding } from "./ElegantTileHeader";

export interface Props {
  item: Item;
  id?: string;
}

export const StandardHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  paddingTop: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
  paddingLeft: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
  paddingRight: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
}));

export const Title = styled(TypographyWithEllipsis)(() => ({
  fontWeight: "bold",
  display: "block",
}));

export const StandardPartnerName = styled(Title)(({ theme }) => ({
  flex: "1",
  color: theme.palette.text.tileDefault,
  marginBottom: theme.settings.tiles.spacing ? getTileSpacing(theme) : ".7rem",
  paddingLeft: getHeaderItemPadding(theme),
  paddingRight: getHeaderItemPadding(theme),
  marginTop: theme.settings.tiles.type === "elegant" ? "0.5rem" : "0",
  fontSize: theme.typography.h5.fontSize,
}));

export const StyledStandardPartnerName = styled(StandardPartnerName)(() => ({
  marginBottom: 0,
}));

export const StandardDealName = styled(Title)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  flexBasis: theme.settings.tiles.type === "standard" ? "100%" : "auto",
  color: theme.palette.primary.main,
  width: 0,
  minWidth: "100%",
  WebkitLineClamp: `${
    theme.settings.tiles.titlesMultipleRows ? `2 !important` : `1 !important`
  }`,
  lineHeight: "1.5rem",
  maxHeight: "3rem",
  display: "-webkit-box",
  paddingLeft: getHeaderItemPadding(theme),
  paddingRight: getHeaderItemPadding(theme),
  marginTop:
    (theme.settings.tiles.type === "elegant" &&
      !theme.settings.tiles.titleContent.showVendorName) ||
    theme.settings.tiles.type === "standard"
      ? "0.5rem"
      : "0",
}));

export const StandardSavingAmount = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.light,
  flexBasis: theme.settings.tiles.type === "standard" ? "100%" : "auto",
}));

export const tileLogoMargin = "0.5rem";
const TileLogoContainer = styled("div")(() => ({
  marginRight: tileLogoMargin,
}));

const StandardTileHeader: FunctionComponent<Props> = ({
  id,
  item: { name, seller, deal },
}: Props) => {
  const theme = useTheme();
  const logo = seller.symbolLogoUrl || seller.logoUrl;
  const { showVendorName } = theme.settings.tiles.titleContent;
  return (
    <StandardHeader id={id}>
      {logo && (
        <TileLogoContainer>
          <TileLogo
            logoUrl={logo}
            orgName={seller.name}
            width={50}
            height={50}
          />
        </TileLogoContainer>
      )}
      {showVendorName && (
        <StyledStandardPartnerName variant="h1" noWrap title={seller.name}>
          {seller.name}
        </StyledStandardPartnerName>
      )}
      <StandardDealName variant={showVendorName ? "h2" : "h1"} title={name}>
        {theme.settings.tiles.titleContent.showListingName ? name : deal?.title}
      </StandardDealName>
      {theme.settings.tiles.titleContent.showSavingAmount &&
        !!deal?.averageTotalSavingsAmount && (
          <StandardSavingAmount>
            <EarningsBox
              color={theme.palette.tileOthersColor.main}
              averageTotalSavingsAmount={deal.averageTotalSavingsAmount}
              averageTotalSavingsPrefix={deal.averageTotalSavingsPrefix}
              averageTotalSavingsCurrency={deal.averageTotalSavingsCurrency}
            />
          </StandardSavingAmount>
        )}
    </StandardHeader>
  );
};

export default StandardTileHeader;
