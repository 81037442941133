import {
  Box,
  ListItem,
  Rating,
  styled,
  Typography,
  useTheme,
  ClickAwayListener,
  Popper,
  Paper,
  CSSObject,
} from "@mui/material";
import Image from "next/legacy/image";
import {
  FunctionComponent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import { Size } from "../../../types/theme";
import { getTileSpacing } from ".";
import { useConfiguration } from "../../../dataAccess/api/configuration";
import { Item } from "../../../dataAccess/api/items";
import { TypographyWithEllipsis } from "../TypographyWithEllipsis";
import { ThemeChip, getThemeChipPadding } from "../ThemeComponents/ThemeChip";
import {
  defaultFocusOutline,
  handleKeyEvent,
} from "../../../helpers/accessibility";

interface Props {
  item: Item;
}

const Description = styled(TypographyWithEllipsis)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  marginBottom: theme.settings.tiles.spacing ? getTileSpacing(theme) : "1rem",
  marginTop: theme.settings.tiles.spacing ? getTileSpacing(theme) : "1rem",
  color: theme.palette.text.tileDefault,
  lineHeight: "1.3",
}));

const Container = styled("div")(({ theme }) => ({
  paddingBottom: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
  paddingLeft: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
  paddingRight: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
}));

const Categories = styled("button")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexWrap: "nowrap",
  listStyle: "none",
  marginTop: "0.5rem",
  maxWidth: "80%",
  overflow: "hidden",
  cursor: "pointer",
  alignItems: "center",
  padding: 0,
  background: "none",
  border: 0,
  height: 30,
  "&:focus": {
    outline: defaultFocusOutline(theme),
    borderRadius: theme.shape.borderRadius,
  },
}));

function CategoryStyles() {
  return {
    padding: 0,
    width: "fit-content",
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "0.3rem",
    maxWidth: "100%",
  };
}

const CategoryItem = styled(ListItem)(CategoryStyles);

const FirstCategoryContent = styled("div")(CategoryStyles);

const Endorsements = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "1rem",
  alignItems: "flex-start",
}));

const EndorsedText = styled(Typography)(({ theme }) => ({
  fontSize: `${theme.typography.caption.fontSize} !important`,
  color: theme.palette.text.tileDefault,
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
}));

const TileThemeChip = styled(ThemeChip)(({ theme }) => {
  const { chips } = theme.settings;

  const tileChipSizes: Record<Size["size"], CSSObject> = {
    extraSmall: getThemeChipPadding(0.875, theme),
    small: getThemeChipPadding(1.2, theme),
    medium: getThemeChipPadding(1.6, theme),
    large: getThemeChipPadding(2, theme),
    extraLarge: getThemeChipPadding(2.5, theme),
  };

  return {
    ...tileChipSizes[chips.size],
    height: "auto",
    "&.MuiChip-outlined": {
      "& .MuiChip-label": {
        padding: 0,
        fontSize: "0.7rem",
      },
    },
  };
});

const EllipsisThemeChip = styled(TileThemeChip)(() => ({
  cursor: "pointer",
  paddingLeft: "0.3rem !important",
  paddingRight: "0.3rem !important",
  "& .MuiChip-label": {
    width: "0.7rem",
    display: "block",
  },
}));

const StyledPaper = styled(Paper)(() => ({
  maxWidth: "12rem",
  display: "flex",
  flexWrap: "wrap",
}));

const TileBody: FunctionComponent<Props> = ({
  item: {
    partnerDescription,
    description,
    endorsed,
    recommendationScore = 0,
    categories = [],
  },
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const ref = useRef<null | HTMLDivElement>(null);
  const { configuration } = useConfiguration();
  const theme = useTheme();

  const handleCategoriesClick = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.preventDefault();
      if (event?.currentTarget) {
        setAnchorEl(event.currentTarget);
      }
      setCategoriesOpen(!categoriesOpen);
    },
    [categoriesOpen],
  );

  const handleCloseCategories = useCallback(() => setCategoriesOpen(false), []);

  const handleOpenCategories = useCallback(() => {
    setAnchorEl(ref.current);
    setCategoriesOpen(true);
  }, []);

  const handleKeyDownCategories = useCallback(
    (e: KeyboardEvent<HTMLElement>) => {
      handleCloseCategories();
      handleKeyEvent({
        onEnter: handleOpenCategories,
      })(e);
    },
    [handleCloseCategories, handleOpenCategories],
  );

  const firstChip = (
    <FirstCategoryContent ref={ref}>
      <TileThemeChip
        variant="outlined"
        color="primary"
        title={categories[0]?.name}
        label={categories[0]?.name}
      />
    </FirstCategoryContent>
  );

  return (
    <Container>
      {theme.settings.tiles.bodyContent.partnerDescription &&
        partnerDescription && <Description>{partnerDescription}</Description>}
      {theme.settings.tiles.bodyContent.dealDescription && (
        <Description data-test="tile-description">{description}</Description>
      )}
      {theme.settings.tiles.recommendations && recommendationScore > 0 && (
        <Rating value={recommendationScore} readOnly size="small" />
      )}
      {theme.settings.tiles.categories &&
        (categories.length <= 1 ? (
          firstChip
        ) : (
          <ClickAwayListener onClickAway={handleCloseCategories}>
            <Categories
              onClick={handleCategoriesClick}
              tabIndex={0}
              onKeyDown={handleKeyDownCategories}
              aria-expanded={categoriesOpen}
            >
              <StyledPopper
                placement="bottom-start"
                open={categoriesOpen}
                anchorEl={anchorEl}
                hidden={!categoriesOpen}
                aria-hidden={!categoriesOpen}
                aria-live="polite"
              >
                <StyledPaper elevation={5}>
                  <ul>
                    {categories.slice(1).map(({ name }, idx) => (
                      <CategoryItem key={idx}>
                        <TileThemeChip
                          variant="outlined"
                          color="primary"
                          title={name}
                          label={name}
                        />
                      </CategoryItem>
                    ))}
                  </ul>
                </StyledPaper>
              </StyledPopper>
              {firstChip}
              {categories.length > 1 &&
                (theme.settings.seeMore === "ellipsis" ? (
                  <EllipsisThemeChip
                    variant="outlined"
                    color="primary"
                    label="⋯"
                  />
                ) : (
                  <Typography color={theme.palette.success.light}>
                    +{categories.length - 1}
                  </Typography>
                ))}
            </Categories>
          </ClickAwayListener>
        ))}
      {(endorsed && configuration && (
        <Endorsements>
          <EndorsedText>ENDORSED BY</EndorsedText>
          {configuration?.signUpLogoUrl ? (
            <Image
              alt={`${configuration?.name} logo`}
              src={configuration.signUpLogoUrl}
              objectFit="contain"
              width={30}
              height={30}
            />
          ) : (
            <EndorsedText>{configuration.name}</EndorsedText>
          )}
        </Endorsements>
      )) || <Box />}
    </Container>
  );
};

export default TileBody;
