import { forwardRef, FunctionComponent } from "react";
import { styled, alpha, darken, useTheme, Theme } from "@mui/material";
import Link from "next/link";
import { isNumber } from "../../../helpers/object";
import { Item } from "../../../dataAccess/api/items";
import { useShadows } from "../../../builder/Theme";
import StackedTileHeader from "./StackedTileHeader";
import PlainTileHeader from "./PlainTileHeader";
import CompactTileHeader from "./CompactTileHeader";
import TileBody from "./TileBody";
import StandardTileHeader from "./StandardTileHeader";
import SkeletonLoader from "../SkeletonLoader";
import { NeutralAnchor } from "../anchor/NeutralAnchor";
import ElegantTileHeader, {
  tileLogoContainerClassName,
} from "./ElegantTileHeader";

interface Props {
  item: Item;
  loading: boolean;
}

export const getTileSpacing = (theme: Theme) => {
  const { spacing } = theme.settings.tiles;

  const sizes = {
    extraSmall: theme.spacing(0.5),
    small: theme.spacing(1.25),
    medium: theme.spacing(2),
    large: theme.spacing(2.5),
    extraLarge: theme.spacing(3),
  };

  // This case covers the scenario where the user has set a spacing number
  if (isNumber(spacing)) {
    return Number(spacing);
  }

  return sizes[spacing];
};

const Container = styled("div")(({ theme }) => {
  const boxShadow = useShadows(theme.settings.tiles.shadows);
  return {
    backgroundColor: theme.palette.tileBackground.default,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    border: `${theme.typography.pxToRem(
      theme.settings.tiles.border.thickness,
    )} solid ${darken(
      alpha(
        theme.palette.tileBackground.default,
        theme.settings.tiles.border.opacity,
      ),
      0.2,
    )}`,
    overflow: "hidden",
    borderRadius: theme.typography.pxToRem(theme.settings.tiles.border.radius),
    [`& .${tileLogoContainerClassName}`]: {
      transition: "100ms",
    },
    "&:hover": {
      borderColor:
        (theme.settings.tiles.hoverVariant === "border" ||
          theme.settings.tiles.hoverVariant === "both") &&
        theme.palette.primary.main,
      [`& .${tileLogoContainerClassName}`]: {
        minHeight: "6.5rem !important",
      },
      ...(theme.settings.tiles.hoverVariant === "background" ||
      theme.settings.tiles.hoverVariant === "both"
        ? {
            backgroundColor: theme.settings.tiles.elegantBackgroundHoverColor
              ? theme.settings.tiles.elegantBackgroundHoverColor
              : theme.palette.text.tileDefault,
            "& p, h5": {
              color: theme.settings.tiles.elegantTextHoverColor
                ? `${theme.settings.tiles.elegantTextHoverColor} !important`
                : `${theme.palette.tileBackground.default} !important`,
            },
          }
        : {}),
    },
    boxShadow,
    height: "100%",
  };
});

const StyledSkeletonTopSection = styled("div")(() => ({
  display: "flex",
  marginBottom: "1rem",
  alignItems: "center",
  gap: "1rem",
}));

const StyledSkeletonRectangular = styled(SkeletonLoader)(() => ({
  marginTop: "1rem",
  height: "3rem",
}));

const StyledSkeletonAvatar = styled(SkeletonLoader)(() => ({
  width: "3rem",
  height: "3rem",
}));

const StyledSkeletonAvatarText = styled(SkeletonLoader)(() => ({
  width: "50%",
  height: "1rem",
}));

const StyledSkeletonContainer = styled("div")(() => ({
  width: "100%",
}));

const Card = forwardRef(({ onClick, href, item, theme }: any, ref: any) => {
  const id = `item-header-${item.id}`;
  return (
    <Container>
      <NeutralAnchor
        href={href}
        onClick={onClick}
        ref={ref}
        aria-labelledby={id}
      >
        {theme.settings.tiles.type === "standard" && (
          <StandardTileHeader item={item} id={id} />
        )}
        {theme.settings.tiles.type === "stacked" && (
          <StackedTileHeader item={item} id={id} />
        )}
        {theme.settings.tiles.type === "plain" && (
          <PlainTileHeader item={item} id={id} />
        )}
        {theme.settings.tiles.type === "compacted" && (
          <CompactTileHeader item={item} id={id} />
        )}
        {theme.settings.tiles.type === "elegant" && (
          <ElegantTileHeader item={item} id={id} />
        )}
        <TileBody item={item} />
      </NeutralAnchor>
    </Container>
  );
});

const Tile: FunctionComponent<Props> = ({ item, loading }: Props) => {
  const theme = useTheme();
  if (!item || loading) {
    return (
      <StyledSkeletonContainer>
        <StyledSkeletonTopSection>
          <StyledSkeletonAvatar variant="circular" />
          <StyledSkeletonAvatarText variant="text" />
        </StyledSkeletonTopSection>
        <SkeletonLoader variant="text" />
        <SkeletonLoader variant="text" width="60%" />
        <StyledSkeletonRectangular variant="rectangular" />
        <StyledSkeletonRectangular variant="rectangular" width="50%" />
      </StyledSkeletonContainer>
    );
  }

  return (
    <Link href={`/${item.slug}`} passHref legacyBehavior shallow>
      <Card item={item} theme={theme} />
    </Link>
  );
};

export default Tile;
