import { styled, useTheme } from "@mui/material";
import { FunctionComponent } from "react";
import EarningsBox from "../EarningsBox";
import {
  StackedDealName,
  StackedSavingAmount,
  StackedPartnerName,
} from "./StackedTileHeader";
import {
  StandardHeader,
  Props as BaseTileHeaderProps,
} from "./StandardTileHeader";

const PlainHeader = styled(StandardHeader)(({ theme }) => ({
  flexWrap: "nowrap",
  alignItems: "flex-start",
  flexDirection: "column",
  paddingTop: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
  paddingLeft: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
  paddingRight: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
}));

const PlainTileHeader: FunctionComponent<BaseTileHeaderProps> = ({
  id,
  item: { name, seller, deal },
}: BaseTileHeaderProps) => {
  const theme = useTheme();
  const { showVendorName } = theme.settings.tiles.titleContent;
  return (
    <PlainHeader id={id}>
      {theme.settings.tiles.titleContent.showVendorName && (
        <StackedPartnerName variant="h1" noWrap title={seller.name}>
          {seller.name}
        </StackedPartnerName>
      )}
      <StackedDealName variant={showVendorName ? "h2" : "h1"} title={name}>
        {showVendorName ? name : deal?.title}
      </StackedDealName>
      {theme.settings.tiles.titleContent.showSavingAmount &&
        !!deal?.averageTotalSavingsAmount && (
          <StackedSavingAmount>
            <EarningsBox
              color={theme.palette.tileOthersColor.main}
              averageTotalSavingsAmount={deal.averageTotalSavingsAmount}
              averageTotalSavingsPrefix={deal.averageTotalSavingsPrefix}
              averageTotalSavingsCurrency={deal.averageTotalSavingsCurrency}
            />
          </StackedSavingAmount>
        )}
    </PlainHeader>
  );
};

export default PlainTileHeader;
