import { FunctionComponent, PropsWithChildren } from "react";
import { ButtonProps } from "@mui/material/Button";
import { ThemeButton } from "./ThemeComponents/ThemeButton";

interface Props {
  url: string;
}

const CTAButton: FunctionComponent<PropsWithChildren<Props & ButtonProps>> = ({
  url,
  children,
  variant,
}) => (
  <ThemeButton target="_blank" href={url} variant={variant || "contained"}>
    {children}
  </ThemeButton>
);

export default CTAButton;
